"use strict";

var btnContact = document.querySelector(".nav__button")
var boiteContact = document.querySelector(".main__nav")

btnContact.addEventListener("click", contact)

function contact (){
  boiteContact.classList.toggle("none")
}

var buttonElement = document.querySelector(".fleche");
var citationElement = document.querySelector(".citation");
var autorElement = document.querySelector(".auteur");
var tabCitation = ["L'homme est un loup pour l'homme. ", "Il m'a fait trop de bien pour en dire du mal, - Il m'a fait trop de mal pour en dire du bien. ", 
" Rien de grand ne s'est accompli dans le monde sans passion. ", 
"On n'est pas sérieux, quand on a dix-sept ans ", "Science sans conscience n'est que ruine de l'âme.", "L'homme n'est pas né pour le repos.", 
"Familles! je vous hais! Foyers clos; portes refermées; possessions jalouses du bonheur."];
var tabAutor = ["Plaute", "Pierre Corneille", "Georg Wilhelm Friedrich Hegel", "Arthur Rimbaud", "François Rabelais", "Voltaire", "André Gide"];

if (buttonElement) {
  buttonElement.addEventListener("click", response3);
} 
var aléatoire = getRandomIntInclusive (0, tabCitation.length -1);

function getRandomIntInclusive(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min +1)) + min;
}

function response3() {
  var aléatoire = getRandomIntInclusive(0, tabCitation.length -1);
  console.log(aléatoire);
  citationElement.innerHTML = tabCitation[aléatoire];
  autorElement.innerHTML = tabAutor[aléatoire];
}

